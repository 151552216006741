import { A, redirect, useLocation, useNavigate } from "@solidjs/router";
import { For, Show } from "solid-js";
import { activeLinkGradient, hubbleLogoSmall } from "~/assets/assets";
import { useProcurement } from "~/context/procurement";
import { logout } from "~/server/apis/client_apis";
import { Cookie } from "~/types";
import { clearCookie } from "~/utils/client_cookie";
import { PhosphorIcon } from "~/widgets/icons";
import { useModal } from "../modal";
import { openIntercom } from "~/utils/third_party/intercom";
import { toRupees } from "~/utils/number";

export const Sidebar = () => {
  const location = useLocation();

  const { openModal, closeModal } = useModal()!;
  const { procurement } = useProcurement();

  const navItems = [
    { path: "/catalogue", label: "Catalogue", icon: "shopping-bag-open" },
    { path: "/purchases", label: "My purchases", icon: "receipt" },
  ];

  const logoutHandler = async () => {
    await logout();

    localStorage.removeItem("sessionId");
    clearCookie(Cookie.SessionId);
    clearCookie(Cookie.Mode);
    clearCookie(Cookie.ClientId);

    return redirect("/login");
  };

  return (
    <aside class="flex h-screen w-[268px] flex-col justify-between bg-[#0F0E12] py-4 text-white">
      <div>
        <div class="flex items-center px-4 pb-4">
          <img src={hubbleLogoSmall} alt="hubble" class="mr-4" />
          <Show when={procurement.userProfile.logo}>
            <img
              src={procurement.userProfile.logo}
              alt="logo"
              class="h-6 w-20"
            />
          </Show>
        </div>
        <div class="flex justify-between border-y border-basePrimaryDark px-4 py-2">
          <p class="text-medium text-baseSecondaryMedium">Balance</p>
          <p class="text-bold">{toRupees(procurement.balance)}</p>
        </div>
        <nav class="py-4 text-subtitleSmall">
          <ul>
            <For each={navItems}>
              {(item) => (
                <A
                  href={item.path}
                  activeClass="bg-basePrimary text-white"
                  inactiveClass="pl-7 text-baseSecondaryMedium"
                  class="relative flex items-center gap-2 px-4 py-2.5  hover:text-white"
                >
                  <Show when={location.pathname === item.path}>
                    <hr class="h-5 w-0.5 rounded-sm border-2 border-white" />
                    <img src={activeLinkGradient} class="absolute" alt="" />
                  </Show>
                  <PhosphorIcon name={item.icon} fontSize={20} />
                  {item.label}
                </A>
              )}
            </For>
          </ul>
        </nav>
      </div>
      <div class="text-medium text-baseSecondaryMedium">
        <p
          class="flex cursor-pointer items-center gap-2 px-4 py-2.5"
          onClick={() => {
            openIntercom();
          }}
        >
          <PhosphorIcon name="chat-dots" />
          Help & Support
        </p>
        <div class="flex justify-between px-4 py-2.5">
          <div class="flex items-center gap-2 ">
            <PhosphorIcon name="user-circle" />
            <p>{procurement.userProfile.name}</p>
          </div>
          <div class="flex items-center gap-4">
            <hr class="h-5 w-px border border-gray-800" />
            <button
              class="text-f12 font-medium underline"
              onClick={() => {
                return openModal(() => {
                  return (
                    <div class="flex flex-col justify-center">
                      <div class="mb-6  mt-8 flex flex-col items-center justify-center">
                        <div class="mb-4 flex h-20 w-20 items-center justify-center rounded-full bg-[#FFECE9]">
                          <PhosphorIcon
                            name="sign-out"
                            fontSize={48}
                            class="text-errorDark"
                            size="light"
                          />
                        </div>
                        <p class="w-4/5 text-center text-h3 text-textDark">
                          Are you sure you want to log out?{" "}
                        </p>
                      </div>
                      <div class="border-t border-baseTertiaryDark p-4">
                        <div class="flex gap-3">
                          <button
                            class="w-full rounded-lg border border-basePrimaryDark px-4 py-2.5 text-subtitleSmall text-textDark"
                            onClick={closeModal}
                          >
                            Cancel
                          </button>
                          <button
                            class="w-full rounded-lg bg-errorDark px-4 py-2.5 text-subtitleSmall text-white"
                            onClick={logoutHandler}
                          >
                            Yes, log out
                          </button>
                        </div>
                      </div>
                    </div>
                  );
                }, "md:w-96");
              }}
            >
              Logout
            </button>
          </div>
        </div>
      </div>
    </aside>
  );
};
