import { createSignal, For, onCleanup, Show } from "solid-js";
import { useProcurement } from "~/context/procurement";
import { PhosphorIcon } from "~/widgets/icons";
import { useModal } from "../modal";
import { procurementOrderInitiate } from "~/server/apis/client_apis";
import { useNavigate } from "@solidjs/router";
import { debounce, throttle } from "@solid-primitives/scheduled";
import { toRupees } from "~/utils/number";
import toast from "solid-toast";

export const Cart = () => {
  const { procurement, updateQuantity, clearCart } = useProcurement();

  const { openModal, closeModal } = useModal()!;

  const [initOrderJob, setInitOrderJob] = createSignal(false);
  const [error, setError] = createSignal<string | null>(null);

  const navigator = useNavigate();

  let notesRef: HTMLInputElement | undefined;

  const initiateOrder = async () => {
    setInitOrderJob(true);
    try {
      const result = await procurementOrderInitiate({
        totalAmount: procurement.cart.totalAmount,
        products: procurement.cart.products.map((product) => ({
          productId: product.productId,
          amount: product.amount,
          denominationDetails: [
            {
              denomination: product.denomination,
              quantity: product.quantity,
            },
          ],
        })),
        notes: notesRef?.value,
      });
      closeModal();
      if (result) {
        clearCart();
        navigator("/order/" + result.id);
      }
    } catch (error: any) {
      console.error("Failed to initiate order:", error);
      setError(error.message);
    }
    setInitOrderJob(false);
  };

  onCleanup(() => {
    setError(null);
  });

  const debouncedUpdateQuantity = debounce(updateQuantity, 300);
  const throttleUpdateQuantity = throttle(updateQuantity, 300);

  return (
    <div class="flex h-screen w-[268px] flex-col border-l">
      <div class="sticky top-0 flex items-center justify-between border-b border-gray-200 px-5 py-4">
        <h1 class="flex items-center gap-2 text-bold text-textDark">
          Cart{" "}
          <span class="text-medium text-textNormal">
            • {procurement.cart.totalQuantity} added
          </span>
        </h1>
      </div>

      <Show
        when={procurement.cart.totalQuantity}
        fallback={
          <div class="m-auto flex w-32 flex-col items-center justify-center gap-1.5">
            <div class="flex h-14 w-14 items-center justify-center rounded-full bg-baseTertiaryMedium">
              <PhosphorIcon name="shopping-cart-simple" fontSize={32} />
            </div>
            <p class="text-center text-medium text-textDark">
              Products you add will appear here
            </p>
          </div>
        }
      >
        <Show
          when={procurement.cart.totalQuantity > 0}
          fallback={
            <div class="m-auto flex w-32 flex-col items-center justify-center gap-1.5">
              <div class="flex h-14 w-14 items-center justify-center rounded-full bg-baseTertiaryMedium">
                <PhosphorIcon name="shopping-cart-simple" fontSize={32} />
              </div>
              <p class="text-center text-medium text-textDark">
                Products you add will appear here
              </p>
            </div>
          }
        >
          <div class="h-4/5 overflow-y-auto">
            <For each={procurement.cart.products}>
              {(product) => (
                <div class="flex items-center justify-between gap-2 p-3">
                  <div class="flex items-center space-x-4">
                    <img
                      src={product.detail.logoUrl}
                      alt={product.detail.brandName}
                      class="h-11 w-11 rounded-full"
                    />
                  </div>
                  <div class="flex-1">
                    <h2 class="text-mediumBold text-textDark">
                      {product.detail.brandName}
                    </h2>
                    <p class="text-f12Bold text-textNormal">
                      ₹{product.denomination}
                    </p>
                  </div>
                  <div class="flex flex-col items-end gap-1">
                    <div class="flex h-5 w-16 items-center justify-between rounded-md border border-baseTertiaryDark">
                      <button
                        type="button"
                        class="text-sm shadow-sm inline-flex size-6 items-center justify-center gap-x-2 rounded-md  font-medium text-black focus:outline-none disabled:pointer-events-none disabled:opacity-50"
                        onClick={() =>
                          throttleUpdateQuantity(
                            product.productId,
                            product.denomination,
                            Math.max(product.quantity - 1, 0)
                          )
                        }
                      >
                        <PhosphorIcon name="minus" fontSize={8} size="bold" />
                      </button>
                      <input
                        class="[&::-webkit-outer-spin-button] :appearance-none w-6 border-0 bg-transparent p-0 text-center text-f12Bold text-black hover:underline focus:ring-0 [&::-webkit-inner-spin-button]:appearance-none"
                        type="number"
                        value={product.quantity}
                        onInput={(event) => {
                          const input = event.target as HTMLInputElement;
                          const newQuantity = parseInt(input.value, 10);

                          if (!isNaN(newQuantity) && newQuantity >= 0) {
                            debouncedUpdateQuantity(
                              product.productId,
                              product.denomination,
                              newQuantity
                            );
                          } else if (input.value === "") {
                            debouncedUpdateQuantity(
                              product.productId,
                              product.denomination,
                              0
                            );
                          }
                        }}
                      />
                      <button
                        type="button"
                        class="text-sm shadow-sm inline-flex size-6 items-center justify-center gap-x-2 rounded-md font-medium text-black focus:outline-none disabled:pointer-events-none disabled:opacity-50"
                        onClick={() =>
                          throttleUpdateQuantity(
                            product.productId,
                            product.denomination,
                            product.quantity + 1
                          )
                        }
                      >
                        <PhosphorIcon name="plus" fontSize={8} size="bold" />
                      </button>
                    </div>
                    <p class="text-f12Bold text-textDark">
                      Total {toRupees(product.amount)}
                    </p>
                  </div>
                </div>
              )}
            </For>
          </div>
        </Show>

        <div class="sticky bottom-0 border-t border-gray-200 p-3">
          <div class="mb-2 flex justify-between">
            <span class="text-medium text-textDark">Item total</span>
            <span class="text-mediumBold">
              {toRupees(procurement.cart.totalAmount)}
            </span>
          </div>
          <div class="mb-2 flex justify-between">
            <span class="text-medium text-textDark">Discount</span>
            <span class="text-mediumBold text-successDark">
              -{toRupees(procurement.cart.totalDiscount)}
            </span>
          </div>
          <div class="mb-4 flex justify-between">
            <span class="text-bold text-textDark">
              To pay{" "}
              <span class="text-small text-textNormal">
                (Incl. of all taxes)
              </span>
            </span>
            <span class="text-bold text-textDark">
              {toRupees(
                procurement.cart.totalAmount - procurement.cart.totalDiscount
              )}
            </span>
          </div>
          <button
            onClick={() => {
              const amountToPay =
                procurement.cart.totalAmount - procurement.cart.totalDiscount;

              if (amountToPay > procurement.balance) {
                toast.error("Insufficient wallet balance", {
                  style: {
                    color: "#fff",
                    background: "#BB2D13",
                    "border-radius": "12px",
                    "font-family": "Inter",
                    "font-size": "13px",
                    "font-weight": 600,
                  },
                  icon: (
                    <PhosphorIcon
                      name="warning"
                      class="text-white"
                      fontSize={18}
                      size="bold"
                    />
                  ),
                });
                return;
              }

              openModal(() => {
                return (
                  <div class="flex flex-col justify-center">
                    <div class="mb-6  mt-8 flex flex-col items-center justify-center">
                      <div class="mb-4 flex h-20 w-20 items-center justify-center rounded-full bg-[#DAF7E8]">
                        <PhosphorIcon
                          name="shopping-cart-simple"
                          fontSize={48}
                          class="text-successDark"
                          size="light"
                        />
                      </div>
                      <p class="text-f12Bold uppercase text-successDark">
                        Confirm order
                      </p>
                      <p class="w-4/5 text-center text-h3 text-textDark">
                        ₹{amountToPay} will be debited from your wallet
                      </p>
                      <p class="text-medium text-textNormal">
                        Current balance: ₹{procurement.balance}
                      </p>
                      <Show when={error()}>
                        <div class="text-medium text-errorDark">{error()}</div>
                      </Show>
                    </div>
                    <div class="w-full border-t border-baseTertiaryDark"></div>
                    <div class="mx-2 mt-4 h-10">
                      <input
                        ref={notesRef}
                        type="text"
                        class="h-10  w-full rounded-lg bg-baseTertiaryMedium p-2  text-medium text-textDark placeholder:text-medium placeholder:text-textNormal focus:border focus:border-black"
                        placeholder="Add a note (Optional)"
                        maxLength={40}
                      />
                    </div>
                    <div class=" p-2 pt-4">
                      <button
                        class={`w-full rounded-lg px-4 py-2.5 text-buttonMedium text-white ${
                          initOrderJob()
                            ? "bg-gray-400 opacity-50"
                            : "bg-successDark"
                        }`}
                        onClick={() => {
                          if (initOrderJob()) return;
                          initiateOrder();
                        }}
                      >
                        Confirm & pay
                      </button>
                    </div>
                  </div>
                );
              }, "md:w-[380px]");
            }}
            class="w-full rounded-lg bg-basePrimaryDark py-3 font-semibold text-white"
          >
            Continue
          </button>

          {/* <button
            onClick={() => {
              return openModal(() => {
                return (
                  <div class="flex flex-col justify-center">
                    <div class="mb-6  mt-8 flex flex-col items-center justify-center">
                      <div class="mb-4 flex h-20 w-20 items-center justify-center rounded-full bg-[#DAF7E8]">
                        <PhosphorIcon
                          name="shopping-cart-simple"
                          fontSize={48}
                          class="text-successDark"
                          size="light"
                        />
                      </div>
                      <p class="text-f12Bold uppercase text-successDark">
                        Confirm order
                      </p>
                      <p class="w-4/5 text-center text-h3 text-textDark">
                        ₹
                        {procurement.cart.totalAmount -
                          procurement.cart.totalDiscount}{" "}
                        will be debited from your wallet
                      </p>
                      <p class="text-medium text-textNormal">
                        Current balance: ₹{procurement.balance}
                      </p>
                      <Show when={error()}>
                        <div class="text-medium text-errorDark">{error()}</div>
                      </Show>
                    </div>
                    <div class="border-t border-baseTertiaryDark p-4">
                      <button
                        class={`w-full rounded-lg bg-successDark px-4 py-2.5 text-buttonMedium text-white ${initOrderJob() ? "bg-gray-400 opacity-50" : "bg-successDark"}`}
                        onClick={() => {
                          if (initOrderJob()) return;
                          initiateOrder();
                        }}
                      >
                        Confirm & pay
                      </button>
                    </div>
                  </div>
                );
              }, "md:w-[380px]");
            }}
            class="w-full rounded-lg bg-basePrimaryDark py-3 font-semibold text-white"
          >
            Continue
          </button> */}
        </div>
      </Show>
    </div>
  );
};
