import { RouteSectionProps } from "@solidjs/router";
import { Cart } from "~/components/procurement/cart";
import { Sidebar } from "~/components/procurement/sidebar";
import { ProcurementProvider } from "~/context/procurement";

export default function (props: RouteSectionProps) {
  const isCataloguePage = () => props.location.pathname.includes("catalogue");

  const shouldExcludeSidebar = () =>
    /^\/purchases\/.+/.test(props.location.pathname) ||
    /^\/order\/.+/.test(props.location.pathname);

  return (
    <ProcurementProvider>
      <div class="flex h-screen">
        {!shouldExcludeSidebar() && <Sidebar />} {props.children}{" "}
        {isCataloguePage() && <Cart />}
      </div>
    </ProcurementProvider>
  );
}
